import "../../App.css";
import NavBar from "../../components/pages/Navbar";
import Footer from "../../components/pages/Footer/Footer";
import Models from "../../components/pages/Models";
import Slider from "../../components/pages/Slider";
import React, { useState, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";


function Home() {
  
    useEffect(() => {
      ReactPixel.init("409439213858362", {}, { autoConfig: true, debug: false });
      ReactPixel.pageView();
    }, []);
  
    return (
      <>
        <NavBar />
        <Slider />
        <Models />
        <Footer />
      </>
    );
  }
  
  export default Home;
  