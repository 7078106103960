import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { FaFacebookSquare, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { Link } from "react-scroll";
import "./Slider.css";


import vid1 from "../../assets/images/2.mp4";
import vid2 from "../../assets/images/3.mp4";
import vid3 from "../../assets/images/4.mp4";
import vid4 from "../../assets/images/5.mp4";
import vid5 from "../../assets/images/6.mp4";


import mobile1 from "../../assets/images/2_mobile.mp4";
import mobile2 from "../../assets/images/3_mobile.mp4";
import mobile3 from "../../assets/images/4_mobile.mp4";
import mobile4 from "../../assets/images/5_mobile.mp4";
import mobile5 from "../../assets/images/6_mobile.mp4";

function Slider() {
  const [clientesValue, setClientesValue] = useState(0);

  useEffect(() => {
    clientesValue < 900 &&
      setTimeout(() => setClientesValue(clientesValue + 10), 50);
  }, [clientesValue]);

  return (
    <div className="carousel_shadow">
      <Carousel
        showArrows={false}
        emulateTouch={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        swipeable={true}
        useKeyboardArrows={true}
        margin={0}
        padding={0}
        autoPlay={true}
        interval={5000}
        transitionTime={800}
        className="Carousel_shadow"
      >
        <div>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-90}
            duration={600}
          >
            <video height="auto" autoPlay loop muted playsInline>
              <source
                src={window.innerWidth <= 960 ? mobile1 : vid1}
                type="video/mp4"
              />
            </video>
          </Link>
        </div>
        <div>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-90}
            duration={600}
          >
            <video height="auto" autoPlay loop muted playsInline>
              <source
                src={window.innerWidth <= 960 ? mobile2 : vid2}
                type="video/mp4"
              />
            </video>
          </Link>
        </div>

        <div>
          <a
            href="https://api.whatsapp.com/send?phone=5491139167902&text=Hola,%20quisiera%20realizar%20una%20consulta."
            spy={true}
            smooth={true}
            offset={-40}
            duration={600}
            className="nav-links"
            target="_blank"
            rel="noreferrer"
          >
            <video autoPlay loop muted playsInline>
              <source
                src={window.innerWidth <= 960 ? mobile3 : vid3}
                type="video/mp4"
              />
            </video>
          </a>
        </div>
        <div>
          <a
            href="https://api.whatsapp.com/send?phone=5491139167902&text=Hola,%20quisiera%20realizar%20una%20consulta."
            spy={true}
            smooth={true}
            offset={-40}
            duration={600}
            className="nav-links"
            target="_blank"
            rel="noreferrer"
          >
            <video autoPlay loop muted playsInline>
              <source
                src={window.innerWidth <= 960 ? mobile4 : vid4}
                type="video/mp4"
              />
            </video>
          </a>
        </div>
        <div>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-90}
            duration={600}
          >
            <video height="auto" autoPlay loop muted playsInline>
              <source
                src={window.innerWidth <= 960 ? mobile5 : vid5}
                type="video/mp4"
              />
            </video>
          </Link>
        </div>
      </Carousel>
    </div>
  );
}

export default Slider;
