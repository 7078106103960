import "./App.css";
import React, { useState, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import Home from "./pages/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NoEncontrado from "./pages/NoEncontrado/NoEncontrado";

function App() {
  useEffect(() => {
    ReactPixel.init("409439213858362", {}, { autoConfig: true, debug: false });
    ReactPixel.pageView();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="*" element={<NoEncontrado />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
